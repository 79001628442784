<template>
    <el-header height="172px">
        <div class="top">
            <div class="con">
                <a class="logo">
                    <img src="@/assets/images/logo.png" alt="">
                </a>
                <div class="menu">
                    <router-link to="/register">注册</router-link>
                    <span>|</span>
                    <router-link to="/login">登录</router-link>
                </div>
            </div>
        </div>
        <div class="nav">
            <div class="con">
                <el-menu router :default-active="$route.path" class="el-menu-nav" mode="horizontal">
                    <el-menu-item index="/">首页</el-menu-item>
                    <el-menu-item index="/goods">会员药品信息</el-menu-item>
<!--                    <el-menu-item index="/news">新闻资讯</el-menu-item>-->
                    <el-menu-item index="/about">关于我们</el-menu-item>
                </el-menu>
            </div>
        </div>
    </el-header>
</template>

<script>
export default {
    name: "my-header",
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
header {
    padding: 0;
    .top {
        width: 100%;
        height: 107px;
        background: #f5f5f5;
        border-bottom: 1px solid #fff;
        border-top: 5px solid #1ED0AA;
        .con {
            position: relative;
        }
        .logo {
            display: inline-block;
            margin-top: 10px;
            margin-left: 4px;
        }
        .menu {
            position: absolute;
            right: 0;
            top: 27px;
            span {
                color: #999;
                margin: 0 10px;
            }
            a {
                color: #999;
                &:hover {
                    color: #1ED0AA;
                }
            }
        }
    }
    .nav {
        width: 100%;
        height: 59px;
        line-height: 58px;
        background: url("~@/assets/images/nav_01.jpg") repeat-x;
        .el-menu-nav {
            border-bottom: none;
            background-color: transparent;
            li {
                background: url("~@/assets/images/nav_02.jpg") no-repeat right center;
                text-align: center;
                color: #555555;
                border-bottom: none;
                height: 59px;
                line-height: 58px;
                min-width: 120px;
                font-size: 16px;
                &:hover, &.is-active {
                    color: #ffffff;
                    background: linear-gradient(90deg, #46DEB4, #30D2A4);
                }
            }
        }
    }
}
</style>
