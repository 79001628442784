import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import(/* webpackChunkName: "goods" */ '../views/Goods.vue')
  },
  {
    path: '/detail/40',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "40" */ '../views/Detail/40.vue')
  },
  {
    path: '/detail/41',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "41" */ '../views/Detail/41.vue')
  },
  {
    path: '/detail/42',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "42" */ '../views/Detail/42.vue')
  },
  {
    path: '/detail/43',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "43" */ '../views/Detail/43.vue')
  },
  {
    path: '/detail/44',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "44" */ '../views/Detail/44.vue')
  },
  {
    path: '/detail/45',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "45" */ '../views/Detail/45.vue')
  },
  {
    path: '/detail/46',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "46" */ '../views/Detail/46.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import(/* webpackChunkName: "protocol" */ '../views/Protocol.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "protocol" */ '../views/Service.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
