<template>
    <el-footer class="foot" height="318px">
        <div class="con">
            <div class="fl footfl">
                <dl class="footdl1">
                    <dd><router-link to="/goods">会员药品信息</router-link></dd>
                    <dt>
                        <router-link to="/goods">会员药品</router-link>
                    </dt>
                </dl>
                <dl class="footdl1">
                    <dd><router-link to="/about">关于我们</router-link></dd>
                </dl>
            </div>
            <div class="fl footfm">
                <span class="footfmh1 fl">Copyright © 2020 - 2021   互联网药品信息服务资格证书编号：XXX</span>
                <br>
                <span class="footfmh3 fl">0717-6489486</span>
                <span class="footfmh5 fl">良药邦（湖北）医药科技有限公司</span>
            </div>
        </div>
    </el-footer>
</template>

<script>
    export default {
        name: "my-footer"
    }
</script>

<style lang="scss" scoped>
.foot{ width:100%; height:275px; background:#2c2c2c; border-top:5px solid #1ED0AA; padding-top:38px;font-size: 12px}
.footfl{ height:126px; overflow:hidden}
.footfl dl{ width:150px; float:left; margin-right:20px; line-height:24px;}
.footfl dl a{ color:#c4c4c4; display:block; width:150px;}
.footfl dl a:hover{ color:#fff;}
.footfl dl dd{ line-height:32px; height:36px; display:block; margin-bottom:10px; background:url("~@/assets/images/foot_11.gif") repeat-x bottom; font-weight:bold;}
.footfl dl dt{ display:block; clear:both;}
.footfl .footdl1{ width:120px; overflow:hidden}
.footfl .footdl1 a{ width:90px;}
.footfr{ width:230px;}
.footfr .fx{ line-height:32px; color:#c4c4c4; font-weight:bold; margin-bottom:5px;}
.footfm{ line-height:30px; color:#c4c4c4; background:url("~@/assets/images/foot_11.gif") repeat-x top; padding-top:15px; width:980px;}
.footfm .footfmh2 ,.footfm .footfmh3{ margin-left:46px; display:inline; padding-left:20px; background:url("~@/assets/images/foot_15.gif") no-repeat center left;}
.footfm .footfmh3 { margin-left:0 }
.footfm .footfmh3{ background:url("~@/assets/images/foot_18.gif") no-repeat center left; padding-left:18px;}
.footfm .footfmh4{padding-left:18px; background:url("~@/assets/images/foot_20.gif") no-repeat center left; float:left; margin-left:30px;}
.footfmh5 {margin-left: 20px;}
.footfmh4 a {color:#c4c4c4;}
.footfmh4 a:hover { color:#fff}
.fl {float: left;}
</style>
