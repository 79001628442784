<template>
  <el-container direction="vertical">
      <my-header v-if="layout" />
      <el-main>
          <router-view />
      </el-main>
      <myFooter v-if="layout" />
  </el-container>
</template>

<script>
import myHeader from '@/components/my-header'
import myFooter from '@/components/my-footer'
export default {
    name: 'app',
    components: {myHeader, myFooter},
    computed: {
        path: vm => vm.$route.path,
        layout: vm => vm.path !== '/protocol' && vm.path !== '/service' && vm.path !== '/gservice' && vm.path !== '/gprotocol'
    },
}
</script>

<style lang="scss">
.el-container {
    .el-main {
        padding: 0;
    }
}
</style>
