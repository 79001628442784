<template>
    <div class="home">
        <div class="banner">
            <el-carousel trigger="click" height="515px">
                <el-carousel-item>
                    <div class="kv1"></div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="kv2"></div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="list con">
            <div class="list-title">
                <span>药品信息</span>
                <div class="more">
                    <a class="fla btn_next swiper-button-prev"></a>
                    <a class="fra btn_pre swiper-button-next"></a>
                </div>
            </div>
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                    <router-link to="/detail/40">
                        <img src="@/assets/images/good-1.png" alt="">
                        <span>精制狗皮膏 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/detail/41">
                        <img src="@/assets/images/good-2.png" alt="">
                        <span>铝碳酸镁咀嚼片 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/detail/42">
                        <img src="@/assets/images/good-3.png" alt="">
                        <span>左羟丙哌嗪胶囊 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/detail/43">
                        <img src="@/assets/images/good-4.jpg" alt="">
                        <span>奥美拉唑肠溶胶囊 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/detail/44">
                        <img src="@/assets/images/good-5.png" alt="">
                        <span>氯雷他定片 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/login">
                        <img src="@/assets/images/good-6.jpg" alt="">
                        <span>罗红霉素胶囊 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/login">
                        <img src="@/assets/images/good-7.jpg" alt="">
                        <span>克拉霉素胶囊 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/login">
                        <img src="@/assets/images/good-8.jpg" alt="">
                        <span>氟康唑胶囊 <i class="el-icon-arrow-right"></i></span>
                    </router-link>
                </swiper-slide>
            </swiper>
        </div>
        <div class="link con">
            <div class="title">友情链接 </div>
            <div class="ho_lick_a">
                <p>
                    <a href="http://fda.hubei.gov.cn/" target="_blank">湖北省药品监督管理局</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {},
    data() {
        return {
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 40,
                slidesPerGroup: 1,
                loop: true,
                loopFillGroupWithBlank: true,
                autoplay: true,
                delay: 2000,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.home {
    .banner {
        .kv1, .kv2 {
            width: 100%;
            height: 515px;
        }
        .kv1 {
            background: url("~@/assets/images/kv1.jpg") center;
        }
        .kv2 {
            background: url("~@/assets/images/kv2.jpg") center;
        }
    }
    .list {
        margin-top: 20px;
        .list-title {
            height: 49px;
            border-top: 1px solid #ccc;
            position: relative;
            span {
                line-height: 38px;
                font-size: 15px;
                color: #333;
                border-top: 2px solid #74ac01;
                padding: 0 6px;
                font-weight: bold;
                position: absolute;
                top: -1px;
                left: 0;
            }
            .more {
                width: 60px;
                height: 30px;
                position: absolute;
                top: -15px;
                right: 0;
                a {
                    background: url("~@/assets/images/Product_02.gif") no-repeat;
                    width: 30px;
                    height: 30px;
                    float: left;
                    position: static;
                    margin-top: 0;
                    outline: none;
                    &.fla {
                        background-position: 0 0;
                    }
                    &.fra {
                        background-position: -31px -31px;
                    }
                    &:after {
                        content: '';
                    }
                }
            }
        }
        .swiper {
            .swiper-slide {
                a {
                    display: block;
                }
                img {
                    display: block;
                    width: 220px;
                    height: 220px;
                }
                span {
                    display: block;
                    height: 30px;
                    line-height: 30px;
                    background-color: #1ED0AA;
                    text-align: center;
                    color: white;
                }
            }

        }
    }
    .link {
        background: #f6f6f6;
        margin: 40px auto;
        overflow: hidden;
        padding-bottom: 15px;
        .title {
            line-height: 30px;
            margin: 6px 0;
            font-weight: bold;
            color: #333;
            font-size: 15px;
            padding-left: 12px;
            border-left: 3px solid #1ED0AA;
        }
        .ho_lick_a {
            padding: 2px 20px;
            line-height: 26px;
            a {
                padding: 3px 10px;
                margin: 5px 2px;
                color: #999;
                font-size: 12px;
                &:hover {
                    background-color: #1ED0AA;
                    color: white;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.swiper {
    height: 250px;
    width: 100%;
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
    }
}
</style>
